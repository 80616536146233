import { Fragment, useState, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { Amplify, Auth } from "aws-amplify";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Media from "react-media";

import amplify_config from "../config/aws-amplify-config";
import classes from "./Navbar.module.css";
import AuthContext from "../store/auth-context";

Amplify.configure(amplify_config);

const Navbar = () => {
  // Items to control menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const handleSignout = async (event) => {
    try {
      await Auth.signOut();

      // Logout in context store
      authCtx.logout();

      // Remove currentUser item from localStorage
      localStorage.removeItem("currentUser");

      handleClose();

      // Navigate to login page
      navigate("/login");
    } catch (error) {
      console.error(error);
    }
  };

  const handleNavigate = (uri) => {
    handleClose();
    navigate(uri);
  };

  const largeNavMenu = authCtx.isLoggedIn ? (
    <ul>
      <li>
        <NavLink to="/home">Home</NavLink>
      </li>
      <li>
        <NavLink to="/pricing">Pricing</NavLink>
      </li>
      <li>
        <NavLink to="/checklists">Checklists</NavLink>
      </li>
      <li>
        <NavLink to="/myaccount">My Account</NavLink>
      </li>
      <li>
        <NavLink to="/guide">Guide</NavLink>
      </li>
      {authCtx.isAdmin && (
        <li>
          <NavLink to="/admin">Admin</NavLink>
        </li>
      )}
      <li>
        <Button variant="contained" color="success" onClick={handleSignout}>
          Sign Out
        </Button>
      </li>
    </ul>
  ) : (
    <ul>
      <li>
        <NavLink to="/home">Home</NavLink>
      </li>
      <li>
        <NavLink to="/pricing">Pricing</NavLink>
      </li>
      <li>
        <NavLink to="/guide">Guide</NavLink>
      </li>
      <li>
        <NavLink to="/signup">Sign Up</NavLink>
      </li>
      <li>
        <NavLink to="/login">Log In</NavLink>
      </li>
    </ul>
  );

  const smallNavMenuItems = authCtx.isLoggedIn ? (
    <Menu
      className={classes.smallNavMenu}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "smallNavMenuButton",
      }}
    >
      <MenuItem
        onClick={() => {
          handleNavigate("/home");
        }}
      >
        Home
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleNavigate("/pricing");
        }}
      >
        Pricing
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleNavigate("/checklists");
        }}
      >
        Checklists
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleNavigate("/myaccount");
        }}
      >
        My Account
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleNavigate("/guide");
        }}
      >
        Guide
      </MenuItem>
      {authCtx.isAdmin && (
        <MenuItem
          onClick={() => {
            handleNavigate("/admin");
          }}
        >
          Admin
        </MenuItem>
      )}
      <MenuItem onClick={handleSignout}>Sign Out</MenuItem>
    </Menu>
  ) : (
    <Menu
      className={classes.smallNavMenu}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "smallNavMenuButton",
      }}
    >
      <MenuItem
        onClick={() => {
          handleNavigate("/home");
        }}
      >
        Home
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleNavigate("/pricing");
        }}
      >
        Pricing
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleNavigate("/guide");
        }}
      >
        Guide
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleNavigate("/signup");
        }}
      >
        Sign Up
      </MenuItem>
      <MenuItem
        onClick={() => {
          handleNavigate("/login");
        }}
      >
        Log In
      </MenuItem>
    </Menu>
  );

  const smallNavMenu = (
    <Fragment>
      <IconButton
        id="smallNavMenuButton"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MenuIcon fontSize="large" sx={{ color: "white" }} />
      </IconButton>
      {smallNavMenuItems}
    </Fragment>
  );

  const menu = (
    <Media
      queries={{
        small: "(max-width: 1349px)",
        large: "(min-width: 1350px)",
      }}
    >
      {(matches) => (
        <Fragment>
          {matches.small && smallNavMenu}
          {matches.large && largeNavMenu}
        </Fragment>
      )}
    </Media>
  );

  return (
    <header className={classes.header}>
      <div
        className={classes.logo}
        onClick={() => {
          handleNavigate("/home");
        }}
      >
        <span>Flight Checklists</span>
        <span className={classes.testmarker}> Test</span>
      </div>
      <nav className={classes.nav}>{menu}</nav>
    </header>
  );
};

export default Navbar;
